<template>
  <b-card v-if="$store.state.app.user">
    <!-- Алерты -->
    <b-col v-if="!$store.state.app.user.is_confirmed" class="mt-75 mb-2 p-0">
      <b-alert v-if="$store.state.app.user.account_type != 'ADMIN'" show variant="danger" class="mb-50">
        <h4 class="alert-heading">
          Email не подтвержден. Проверьте ваш почтовый ящик
        </h4>

        <div class="alert-body">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              @click="confirmRetry"
          >Отправить сообщение еще раз
          </b-button
          >
          <b-link class="alert-link"></b-link>
        </div>
      </b-alert>
    </b-col>
    <!-- Аватарка -->
    <b-media no-body>
      <b-media-aside>
        <b-link>

          <b-img
              v-if="!image"
              ref="previewEl"
              rounded
              :src="avatar"
              height="80"
              width="80"
              fluid
          />
          <b-img
              v-if="image"
              ref="previewEl"
              rounded
              :src="image"
              height="80"
              width="80"
              fluid
          />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- Предпросмотр -->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
        >
          Выбрать файл
        </b-button>
        <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @submit.prevent
            @change="onFileChange"
        />

        <!-- Сброс формы -->
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="resetAvatar"
        >
          Сбросить
        </b-button>

<!--        <b-card-text>Вы можете загрузить JPG, GIF, или PNG</b-card-text>-->
      </b-media-body>
    </b-media>

    <!-- Форма -->
    <validation-observer v-slot="{ invalid }">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Адрес организации" label-for="account-address">
              <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
              >
                <b-form-input
                    v-model="options.address"
                    name="address"
                    placeholder="Беларусь, г.Минск, ул. Аранская 13, ком. 9-10 "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    v-model="options.email"
                    name="email"
                    placeholder="email@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Имя" label-for="account-firstName">
              <validation-provider
                  #default="{ errors }"
                  name="firstName"
                  rules="required|alpha_spaces"
              >
                <b-form-input
                    v-model="options.firstName"
                    name="firstName"
                    placeholder="Иван"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Фамилия" label-for="account-lastName">
              <validation-provider
                  #default="{ errors }"
                  name="lastName"
                  rules="required|alpha_spaces"
              >
                <b-form-input
                    v-model="options.lastName"
                    name="lastName"
                    placeholder="Иванов"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Отчество" label-for="account-middleName">
              <validation-provider
                  #default="{ errors }"
                  name="middleName"
              >
                <b-form-input
                    v-model="options.middleName"
                    name="middleName"
                    placeholder="Иванович"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Номер телефона" label-for="account-phone">
              <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
              >
                <b-form-input
                    v-model="options.phone"
                    name="phone"
                    placeholder="+375 44 766 66 68"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Ваша должность" label-for="account-position">
              <validation-provider
                  #default="{ errors }"
                  name="position"
                  rules="required|alpha_spaces"
              >
                <b-form-input
                    v-model="options.position"
                    name="position"
                    placeholder="Менеджер по продажам"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="Название организации"
                label-for="account-organization"
            >
              <validation-provider
                  #default="{ errors }"
                  name="company name"
                  rules="required"
              >
                <b-form-input
                    v-model="options.organization"
                    name="organization"
                    placeholder="Название организации, ИП"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Кнопки -->
          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="!isComplete"
                @click="onUpdate"
            >
              Сохранить изменения
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="mt-2"
                @click="resetForm"
            >
              Отмена
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import BlInput from "@/components/controls/BlInput";
import "@/libs/custom-validate";
import utils from "@/utils";
import api from "@/api";
import {BACKEND_BASE_URL} from "../../../../constants";

export default {
  components: {
    BlInput,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  mounted() {
  },
  watch: {
    '$store.state.app.user': function (newValue, oldValue) {
      if (newValue) {
        this.resetData()
      }
    }
  },
  methods: {
    confirmRetry() {
      const self = this;
      api.auth
          .confirmRetry()
          .then((response) => {
            const data = response.data;
            if (data && data.message) {
              utils.notificate(self, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    resetForm() {
      if (this.$store.state.app.user) {
        this.resetData();
        this.resetAvatar();
      }
    },
    resetData() {

      this.options.address = this.$store.state.app.user.company_address;
      this.options.email = this.$store.state.app.user.email;
      this.options.firstName = this.$store.state.app.user.first_name;
      this.options.lastName = this.$store.state.app.user.last_name;
      this.options.middleName = this.$store.state.app.user.middle_name;
      this.options.phone = this.$store.state.app.user.phone;
      this.options.position = this.$store.state.app.user.position;
      this.options.organization = this.$store.state.app.user.company_name;
      this.options.avatar = this.$store.state.app.user.avatar;
    },
    updateUser() {
      const self = this;
      const id = this.$store.state.app.user.id;
      api.users
          .update(id, {
            email: this.options.email,
            first_name: this.options.firstName,
            last_name: this.options.lastName,
            middle_name: this.options.middleName,
            phone: this.options.phone,
            position: this.options.position,
            company_name: this.options.organization,
            company_address: this.options.address,
            avatar: this.options.avatar,
          })
          .then((response) => {
            const data = response.data;
            self.$store.commit("app/SET_USER", data.result);
            if (data && data.message) {
              utils.notificate(self, data.message);
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.message) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });

    },
    onUpdate() {
      const self = this;

      // Отправка изображения
      console.log(this.profileFile)
      if (self.image) {
        api.files
            .upload(this.profileFile)
            .then((response) => {
              const data = response.data.result;
              console.log(data.path)
              self.options.avatar = `${BACKEND_BASE_URL}${data.path}`;
              self.updateUser()

            })
            .catch((err) => {
              const data = err.response.data;
              if (data && data.message) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        self.updateUser()
      }

    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetAvatar() {
      this.image = null;
    },
  },

  data() {
    return {
      image: "",
      profileFile: null,
      invalid: false,
      options: {
        login: "",
        address: "",
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        position: "",
        organization: "",
        avatar: "",
      },
    };
  },
  computed: {
    isComplete() {
      return true
      // return (
      //     this.options.address &&
      //     this.options.email &&
      //     this.options.firstName &&
      //     this.options.lastName &&
      //     this.options.phone &&
      //     this.options.position &&
      //     this.options.address
      // );
    },
    avatar() {
      if (this.$store.state.app.user.avatar) {
        return this.$store.state.app.user.avatar;
      } else {
        return "/static/noavatar.svg";
      }
    },
    user() {
      return this.$store.state.app.user;
    },
  },
};
</script>
