<template>

  <!--Табы-->
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!--Таба "Основные"-->
    <b-tab active>

      <!-- Заголовок -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Основные</span>
      </template>

      <!--Контент-->
      <account-setting-general
      />
    </b-tab>

    <!--Таба "Сменить пароль"-->
    <b-tab>

      <!--Заголовок-->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Сменить пароль</span>
      </template>

      <!--Контент-->
      <account-setting-password />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import api from '@/api';

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    //Запрос за данными пользователя
    const self = this;
    api.users.me().then(data=>{
      self.$store.commit('app/SET_USER', data.data.result);
    })
    .catch(err=>{
      self.$router.push('/login');      
    })
  },
}
</script>
